@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400&display=swap');

:root {
    --cyan: #00ADFF;
    --purple: #C189CC;
    --light-blue: #2BBCFF;
    --text-cyan: #7BD5FF;
    --black: #000;
    --black-opaque: rgba(0, 0, 0, 0.904);
    --black-dark: rgba(0, 0, 0, 0.9);
    --snowflake: rgba(168, 213, 226, 0.8);
}

html {
    background-image: url("../../public/img/rymd.jpg");
    background-color: var(--black);
    background-position: 0 0;
    background-repeat: repeat;
    min-height: 100vh;
    overflow-y: scroll;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.header-logo {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}

body {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 1.1em;
    color: var(--cyan);
    line-height: 1.6;
}

.home-intro {
    text-align: center;
}

header,
main,
footer {
    width: 90%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 480px) {

    header,
    main,
    footer {
        width: 95%;
    }

    .auth-button {
        width: 100%;
    }
}

@media (min-width: 768px) {

    header,
    main,
    footer {
        width: 50%;
    }
}

@media (min-width: 1024px) {

    header,
    main,
    footer {
        width: 40%;
    }
}

header {
    padding: 20px 0;
    text-align: center;
}

nav {
    margin-top: 10px;
}

nav a {
    display: inline-block;
    margin: 0 5px;
}

main {
    padding: 20px;
    background-color: var(--black-opaque);
    border: 1px solid var(--purple);
}


footer {
    padding: 20px;
    margin-top: 20px;
    background-color: var(--black);
    border-top: 1px solid var(--cyan);
    text-align: center;
}

h1 {
    color: var(--purple);
    font-size: 2.2em;
    margin: 20px 0 10px;
    text-align: center;
}

h2 {
    color: var(--cyan);
    font-size: 2em;
    margin: 20px 0 10px;
    text-align: center;
}

h3 {
    color: var(--purple);
    font-size: 1.6em;
    margin: 15px 0 10px;
}

h4 {
    color: var(--light-blue);
    font-size: 1.3em;
    margin: 10px 0 5px;
}

ul {
    margin: 10px 0 20px 20px;
    padding-left: 20px;
}

iframe {
    margin: 20px auto;
    display: block;
    max-width: 600px;
}

.iframe-bandcamp {
    width: 90%;
    height: 600px;
    border: 0;
}

/* Optional: Add hover effect for the entire form (if needed) 
form:hover {
    border: 1px solid var(--purple); /* Add subtle border on form hover 
    padding: 5px;  Slight padding increase 
    transition: border 0.3s, padding 0.3s;
}*/

/* Optional: Add container styling for forms to ensure layout consistency */
form {
    margin: 20px 0;
    text-align: left;
    width: 100%;
    /* Ensure full width */
    outline: none;
    /* Remove any form outline */
}

label {
    display: block;
    margin: 10px 0 5px;
    color: var(--cyan);
    font-family: 'Raleway', sans-serif;
    /* Ensure Raleway for labels */
}

.form-input {
    display: block;
    width: 100%;
    max-width: 400px;
    /* Match other inputs */
    padding: 8px;
    margin: 5px 0 15px;
    /* Consistent spacing */
    border: 1px solid var(--cyan);
    /* Cyan border from theme */
    background-color: #000000;
    /* Dark background */
    color: var(--cyan);
    /* White text */
    font-family: 'Raleway', sans-serif;
    /* Match your theme */
    box-sizing: border-box;
}

.form-checkbox {
    margin: 5px 0 15px;
    accent-color: var(--cyan);
    /* Cyan checkmark */
}

.form-button {
    display: block;
    width: 100px;
    padding: 8px;
    margin: 10px 0;
    background-color: var(--cyan);
    /* Cyan background */
    color: #fff;
    border: none;
    cursor: pointer;
    font-family: 'Raleway', sans-serif;
    text-align: center;
}

.form-button:hover {
    background-color: var(--purple);
    /* Purple on hover for retro vibe */
}

.error {
    color: var(--purple);
    /* Purple error text */
    margin: 5px 0;
}

/* Remove border from .MuiTextField-root input, rely on MUI’s outline */
.MuiTextField-root input,
.MuiTextField-root textarea {
    width: 100% !important;
    /* Ensure full width */
    padding-top: 6px !important;
    margin-bottom: 0px !important;
    background: var(--black-dark) !important;
    color: var(--text-cyan) !important;
    font-family: 'Raleway', sans-serif !important;
    font-size: 1.2em !important;
    border-radius: 0 !important;
    /* Ensure hard corners */
    box-shadow: none !important;
    /* Remove MUI’s default shadow */
    outline: none !important;
    /* Remove default outline */
    border: none !important;
    /* Remove any custom border on the input */
    height: 25px !important;
    /* Use natural height */
}

/* Rely on MUI’s OutlinedInput outline for borders */
.MuiOutlinedInput-root {
    border-radius: 0 !important;
    /* Hard corners */
    height: auto !important;
    /* Use natural height */
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: var(--light-blue) !important;
    /* Default light blue border */
    border-width: 1px !important;
    /* Thin border */
}

/* Hover effect for inputs (single purple outline) */
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--purple) !important;
    /* Purple on hover */
    border-width: 1px !important;
    /* Thin border */
    transition: border-color .7s !important;
    /* Smooth transition */
}

/* Focus effect for inputs (single tight purple outline, no double border) */
.MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
    border: 2px solid var(--purple) !important;
    /* Tight purple border on focus */
    box-shadow: none !important;
    /* Remove shadow */
    outline: none !important;
    /* Remove outline */
    transition: border 0.3s;
    /* Smooth transition */
}

/* Adjust padding or width if needed to prevent overflow */
.MuiOutlinedInput-input {
    padding: 9px !important;
    /* Adjust for 2px focus border, maintain clean fit */
    height: auto !important;
    /* Ensure natural height */
}

.MuiButton-root {
    border-radius: 0 !important;
    /* Remove rounded corners, force square edges */
    background: var(--cyan) !important;
    color: #fff;
    padding: 10px 20px !important;
    border: none !important;
    font-family: 'Raleway', sans-serif !important;
    font-size: 1em !important;
    text-transform: none !important;
    cursor: pointer;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    margin: 5px 0;
    /* Add vertical margin for spacing in stack */
    height: 40px;
}

.MuiButton-root:hover {
    background: var(--purple) !important;
    transition: 0.4s;
}

.button-stack {
    display: flex;
    flex-direction: column;
    /* Stack buttons vertically */
    align-items: center;
    /* Center buttons horizontally */
    margin: 10px 0;
    /* Add spacing above/below button group */
}

.button-stack .MuiButton-root {
    width: 200px;
    /* Match user.html’s .auth-button width */
    margin: 5px 0;
    /* Vertical spacing between buttons */
}



input[type="text"],
input[type="email"],
input[type="password"],
input[type="url"],
textarea {
    width: calc(100% - 2px);
    padding: 10px;
    margin-bottom: 15px;
    background: var(--black-dark);
    border: 1px solid var(--light-blue);
    color: var(--cyan);
    font-family: 'Raleway', sans-serif;
    font-size: 1em;
}

input[type="file"] {
    margin-bottom: 15px;
    color: var(--text-cyan);
}

button {
    background: var(--cyan);
    color: #fff;
    padding: 10px 20px;
    border: none;
    font-family: 'Raleway', sans-serif;
    font-size: 1em;
    cursor: pointer;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
}

button:hover {
    background: var(--purple);
}

.bandcamp-link {
    color: var(--light-blue);
    text-decoration: none;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.bandcamp-link:hover {
    color: var(--purple);
}

.social-link {
    color: var(--light-blue);
    text-decoration: none;
    margin: 0 5px;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.social-link:hover {
    color: var(--purple);
}

#success-message {
    color: var(--cyan);
    text-align: center;
    margin-top: 15px;
    padding: 10px;
    background: var(--black-dark);
    border: 1px solid var(--purple);
}

.hidden {
    display: none;
}

.bot-field {
    display: none;
}

.auth-button {
    background: var(--cyan);
    color: #fff;
    padding: 10px 20px;
    width: 200px;
    border: none;
    font-family: 'Raleway', sans-serif;
    font-size: 1em;
    cursor: pointer;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    display: block;
    margin: 10px auto;
    text-align: center;
}

.auth-button:hover {
    background: var(--purple);
}

.home-snowflakes .snowflake {
    color: var(--snowflake);
    font-size: 1em;
    font-family: Arial, sans-serif;
    text-shadow: 0 0 5px var(--black);
    position: fixed;
    top: -10%;
    z-index: 500;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    animation-name: snowflakes-fall, snowflakes-shake;
    animation-duration: 10s, 3s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

@keyframes snowflakes-fall {
    0% {
        top: -10%;
    }

    100% {
        top: 100%;
    }
}

@keyframes snowflakes-shake {

    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(80px);
    }
}

.home-snowflakes .snowflake:nth-of-type(0) {
    left: 1%;
    animation-delay: 0s, 0s;
}

.home-snowflakes .snowflake:nth-of-type(1) {
    left: 10%;
    animation-delay: 1s, 1s;
}

.home-snowflakes .snowflake:nth-of-type(2) {
    left: 20%;
    animation-delay: 6s, 0.5s;
}

.home-snowflakes .snowflake:nth-of-type(3) {
    left: 30%;
    animation-delay: 4s, 2s;
}

.home-snowflakes .snowflake:nth-of-type(4) {
    left: 40%;
    animation-delay: 2s, 2s;
}

.home-snowflakes .snowflake:nth-of-type(5) {
    left: 50%;
    animation-delay: 8s, 3s;
}

.home-snowflakes .snowflake:nth-of-type(6) {
    left: 60%;
    animation-delay: 6s, 2s;
}

.home-snowflakes .snowflake:nth-of-type(7) {
    left: 70%;
    animation-delay: 2.5s, 1s;
}

.home-snowflakes .snowflake:nth-of-type(8) {
    left: 80%;
    animation-delay: 1s, 0s;
}

.home-snowflakes .snowflake:nth-of-type(9) {
    left: 90%;
    animation-delay: 3s, 1.5s;
}

.home-snowflakes .snowflake:nth-of-type(10) {
    left: 25%;
    animation-delay: 2s, 0s;
}

.home-snowflakes .snowflake:nth-of-type(11) {
    left: 65%;
    animation-delay: 4s, 2.5s;
}

.music-photons {
    display: block;
    margin: 20px auto;
    max-width: 100%;
    height: auto;
}

#purchases-section,
#downloads-section {
    margin: 20px 0;
}

#purchase-list,
#project-list {
    list-style: none;
    padding: 0;
}

#purchases-section h4,
#downloads-section h4 {
    margin-bottom: 5px;
}

#purchase-list li,
#project-list li {
    padding: 8px 0;
    border-bottom: 1px solid #C189CC;
    /* Match blue buttons */
}

#tenant-signup {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #C189CC;
}

#tenant-form label,
#tenant-form input,
#tenant-form select {
    display: block;
    margin: 5px 0;
}

#tenant-form button {
    background-color: #00ADFF;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

#tenant-form button:hover {
    background-color: #C189CC;
}

#profile-section,
#purchases-section,
#downloads-section,
#tenant-signup {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid var(--purple);
    background: var(--black-opaque);
}

#purchase-list li,
#project-list li {
    padding: 8px 0;
    border-bottom: 1px solid var(--purple);
    /* Match purple borders */
}

#tenant-signup select,
#tenant-signup input {
    width: 100%;
    margin: 5px 0;
    padding: 8px;
    background: var(--black-dark);
    border: 1px solid var(--light-blue);
    color: var(--text-cyan);
    font-family: 'Raleway', sans-serif;
}